export const POSTHOG_EVENTS = {
  customDomainCreated: "customDomain_created",
  customDomainDeleted: "customDomain_deleted",
  destinationCreated: "destination_created",
  destinationUpdated: "destination_updated",
  destinationClicksReseted: "destination_clicks_reseted",
  destinationDeleted: "destination_deleted",
  redirectCreated: "redirect_created",
  redirectUpdated: "redirect_updated",
  redirectDeleted: "redirect_deleted",
  redirectGroupCreated: "redirectGroup_created",
  redirectGroupUpdated: "redirectGroup_updated",
  redirectGroupDeleted: "redirectGroup_deleted",
  billingPortalCheckoutCreated: "billing_portal_checkout_created",
  updatePlanCheckoutCreated: "update_plan_checkout_created",
  cancelPlanCheckoutCreated: "cancel_plan_checkout_created",
  userUpdated: "user_updated",
  userForgotPassword: "user_forgot_password",
  userForgotPasswordConfirm: "user_forgot_password_confirm",
  signUp: "sign_up",
  signIn: "sign_in",
  workspaceCreated: "workspace_created",
  workspaceUpdated: "workspace_updated",
  workspaceDeleted: "workspace_deleted",
  workspaceSelected: "workspace_selected",
  workspaceUserCreated: "workspaceUser_created",
  workspaceUserDeleted: "workspaceUser_deleted",
  workspaceInviteAccepted: "workspace_invite_accepted",
  planFeatureInfoPopupOpened: "plan_feature_info_popup_opened",
  featureLimiterInfoPopupOpened: "feature_limiter_info_popup_opened",
};

export const POSTHOG_FEATURE_FLAGS = {
  userCheckoutFlow: "user_checkout_flow",
  canSigninWithGoogle: "can_signin_with_google",
  requestUserCellphone: "request_user_cellphone",
  globalDiscount: "global_discount",
};
