"use client";

import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@irbano/react-components/dist/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@irbano/react-components/dist/components/ui/form";
import { Input } from "@irbano/react-components/dist/components/ui/input";
import { PhoneInput } from "@irbano/react-components/dist/components/ui/phone-input";
import { useToast } from "@irbano/react-components/dist/components/ui/toast";
import { cn } from "@irbano/react-components/dist/lib/utils";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { signIn } from "next-auth/react";
import {
  useSearchParams,
  useRouter,
  ReadonlyURLSearchParams,
} from "next/navigation";

import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { TranslationProp, useTranslation } from "shared/hooks/useTranslation";
import { trpc } from "utils/trpc";
import { z } from "zod";
import { validatePasswordAndConfirmPassword } from "../shared";
import { OtherLoginStrategies } from "../components/other-login-strategies";
import { useFeatureFlagPayload } from "posthog-js/react";
import { POSTHOG_FEATURE_FLAGS } from "shared/constants/analytics";

export type SignUpFormTranslations = {
  auth: {
    inputs: {
      name: {
        label: string;
        placeholder: string;
      };
      email: {
        label: string;
        placeholder: string;
      };
      cellphone: {
        label: string;
        placeholder: string;
      };
      password: {
        label: string;
        placeholder: string;
      };
      confirmPassword: {
        label: string;
        placeholder: string;
      };
    };
    signUp: {
      errors: {
        invalidPassword: string;
        userAlreadyExists: string;
      };
      signUpButton: string;
      toasts: {
        afterSignUp: {
          title: string;
        };
      };
    };
    signIn: {
      errors: {
        notAuthorized: string;
        passwordResetRequired: string;
      };
      orContinueWith: string;
    };
  };
};

export type SignUpFormProps = {
  translation: TranslationProp<SignUpFormTranslations>;
  callbackUrl?: string;
} & React.HTMLAttributes<HTMLDivElement>;

const signUpSchema = z
  .object({
    name: z
      .string()
      .max(255, "Name cannot be longer than 255 characters")
      .optional(),
    email: z.string().email(),
    password: z.string().min(6),
    confirmPassword: z.string(),
    cellphone: z.string().max(20).optional(),
  })
  .superRefine(validatePasswordAndConfirmPassword);

export const SignUpForm = ({
  className,
  translation,
  callbackUrl,
  ...props
}: SignUpFormProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation({ translation });
  const searchParams = useSearchParams();
  const router = useRouter();
  const { toast } = useToast();

  const requestUserCellphone = useFeatureFlagPayload(
    POSTHOG_FEATURE_FLAGS.requestUserCellphone
  );

  const signUpMutation = trpc.cognito.signUp.useMutation();
  const parsedCallBack = callbackUrl || "/app";

  async function onSubmit(values: z.infer<typeof signUpSchema>) {
    try {
      const signUpResponse = await signUpMutation.mutateAsync(values);

      console.debug("User signUp response", signUpResponse);
      const signInResponse = await signIn("cognito-credentials", {
        ...values,
        callbackUrl: parsedCallBack,
        redirect: true,
      });
      console.debug("SignUp signIn response", signInResponse);

      if (signInResponse?.ok) {
        toast({
          title: t("auth.signUp.toasts.afterSignUp.title"),
          variant: "success",
        });
        return router.replace(parsedCallBack);
      }

      if (signInResponse?.error === "NotAuthorized") {
        return toast({
          title: t("auth.signIn.errors.notAuthorized"),
          variant: "destructive",
        });
      }
      if (signInResponse?.error === "PasswordResetRequired") {
        toast({
          title: t("auth.signIn.errors.passwordResetRequired"),
          variant: "default",
        });

        const params = new URLSearchParams(
          searchParams as ReadonlyURLSearchParams
        );

        params.set("email", values.email);
        return router.push(`/auth/password-reset?${params.toString()}`);
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        if (err.message === "InvalidPasswordException") {
          return toast({
            title: t("auth.signUp.errors.invalidPassword"),
            variant: "destructive",
          });
        }

        if (
          err.message === "UsernameExistsException" ||
          err.message === "User already exists"
        ) {
          return toast({
            title: t("auth.signUp.errors.userAlreadyExists"),
            variant: "destructive",
          });
        }

        console.error("signup error", err);

        return toast({
          title: err.message,
          variant: "destructive",
        });
      }
    }
  }

  const form = useForm<z.infer<typeof signUpSchema>>({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      name: "",
      email: "",
      cellphone: "",
      password: "",
      confirmPassword: "",
    },
  });

  return (
    <div className={cn("grid gap-6", className)} {...props}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("auth.inputs.name.label")}</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    placeholder={t("auth.inputs.name.placeholder")}
                    autoComplete="name"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("auth.inputs.email.label")}</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    placeholder={t("auth.inputs.email.placeholder")}
                    autoComplete="username"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {!requestUserCellphone || requestUserCellphone === "optional" ? (
            <FormField
              control={form.control}
              name="cellphone"
              render={({ field }) => (
                <FormItem className="mt-2">
                  <FormLabel>{t("auth.inputs.cellphone.label")}</FormLabel>
                  <FormControl>
                    <PhoneInput
                      placeholder={t("auth.inputs.cellphone.placeholder")}
                      autoComplete="tel"
                      defaultCountry="BR"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ) : null}

          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem className="mt-2">
                <FormLabel>{t("auth.inputs.password.label")}</FormLabel>
                <FormControl>
                  <div className="relative">
                    <Input
                      type={showPassword ? "text" : "password"}
                      placeholder={t("auth.inputs.password.placeholder")}
                      autoComplete="new-password"
                      {...field}
                    />
                    <Button
                      type="button"
                      variant="ghost"
                      size="sm"
                      className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                      onClick={() => setShowPassword((prev) => !prev)}
                    >
                      {showPassword ? (
                        <EyeIcon className="h-4 w-4" aria-hidden="true" />
                      ) : (
                        <EyeOffIcon className="h-4 w-4" aria-hidden="true" />
                      )}
                      <span className="sr-only">
                        {showPassword ? "Hide password" : "Show password"}
                      </span>
                    </Button>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem className="mt-2">
                <FormLabel>{t("auth.inputs.confirmPassword.label")}</FormLabel>
                <FormControl>
                  <Input
                    type={showPassword ? "text" : "password"}
                    placeholder={t("auth.inputs.confirmPassword.placeholder")}
                    autoComplete="new-password"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            className="w-full"
            isLoading={form.formState.isSubmitting}
          >
            {t("auth.signUp.signUpButton")}
          </Button>

          <OtherLoginStrategies
            callbackUrl={parsedCallBack}
            translations={{
              orContinueWithText: t("auth.signIn.orContinueWith"),
            }}
          />
        </form>
      </Form>
    </div>
  );
};
