import { z } from "zod";

export const validatePasswordAndConfirmPassword = (
  { confirmPassword, password }: { confirmPassword: string; password: string },
  ctx: z.RefinementCtx
) => {
  if (confirmPassword !== password) {
    ctx.addIssue({
      path: ["confirmPassword"],
      code: "custom",
      message: "The passwords did not match",
    });
  }

  if (!/(?=.*[a-z])/.test(password)) {
    ctx.addIssue({
      path: ["password"],
      code: "custom",
      message: "Password must contain at least one lowercase letter",
    });
  }

  if (!/(?=.*[A-Z])/.test(password)) {
    ctx.addIssue({
      path: ["password"],
      code: "custom",
      message: "Password must contain at least one uppercase letter",
    });
  }

  if (!/(?=.*[0-9])/.test(password)) {
    ctx.addIssue({
      path: ["password"],
      code: "custom",
      message: "Password must contain at least one number",
    });
  }

  if (password.length < 8) {
    ctx.addIssue({
      path: ["password"],
      code: "custom",
      message: "Password must be at least 8 characters long",
    });
  }
};
